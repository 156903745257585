import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import List from "../List";
import { toast } from "react-toastify";
import debounce from "lodash.debounce"; // Importa debounce para otimizar chamadas

const ListRoad = () => {
    const url = process.env.REACT_APP_BACKEND_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [roads, setRoads] = useState([]);
    const { user } = useAuth();
    const toastStyle = { fontSize: "13.3px" };

    const columnTitles = [
        { key: "codigo", value: "Código" },
        { key: "name", value: "Nome" },
        { key: "classificcao", value: "Classificação" },
        { key: "extensao", value: "Extensão" },
        { key: "zone", value: "Zona" },
        { key: "lote", value: "Lote" },
        { key: "created_at", value: "Data Criação" },
    ];

    // Busca todas as estradas
    const getRoads = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${url}/road`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });

            const data = response.data.map((v) => {
                const createdAt = new Date(v.created_at).toISOString();
                const formattedDate = createdAt.replace("T", " ").split(".")[0];

                return {
                    ...v,
                    created_at: formattedDate,
                    lote: v.lote.name,
                    zone: v.zone.name,
                    extensao: `${v.extensao} Km`,
                };
            });

            // Ordena as estradas por data de criação
            data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            setRoads(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao buscar estradas", { style: toastStyle });
        } finally {
            setLoading(false);
        }
    }, [url, user.access_token]);

    // Busca estradas filtradas no endpoint /road/search
    const fetchFilteredRoads = async (searchTerm) => {
        if (!searchTerm) {
            getRoads(); // Se a busca estiver vazia, recarrega todas as estradas
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(
                `${url}/road/search`,
                { search: searchTerm }, // Envia o termo de busca no corpo da requisição
                {
                    headers: {
                        Authorization: "Bearer " + user.access_token,
                    },
                }
            );

            // Formata os dados retornados
            const data = response.data.map((v) => {
                const createdAt = new Date(v.created_at).toISOString();
                const formattedDate = createdAt.replace("T", " ").split(".")[0];

                return {
                    ...v,
                    created_at: formattedDate,
                    lote: v.lote.name,
                    zone: v.zone.name,
                    extensao: `${v.extensao} Km`,
                };
            });

            setRoads(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao buscar estradas filtradas", { style: toastStyle });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRoads();
    }, [getRoads]);

    const deleteRoad = async (id) => {
        try {
            await axios.delete(`${url}/road/${id}`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });
            toast.success("Eliminado com sucesso", { style: toastStyle });
            await getRoads();
        } catch (error) {
            console.log(error);
            toast.error("Erro ao eliminar", { style: toastStyle });
        }
    };

    // Filtragem com debounce para evitar chamadas excessivas à API
    const filter = debounce((e) => {
        const searchTerm = e.target.value.trim();
        fetchFilteredRoads(searchTerm);
    }, 500); // Aguarda 500ms antes de fazer a requisição

    return (
        <List
            title="Estrada"
            columnTitles={columnTitles}
            items={roads}
            filter={filter}
            originLink="/listRoad#"
            newLink="/addRoad"
            viewBaseLink="/viewRoad"
            editBaseLink="/editRoad"
            deleteItem={deleteRoad}
            loading={loading}
        />
    );
};

export default ListRoad;
