import React, { useEffect } from "react";
import himg from "./himg.svg";
import { useAuth } from "react-oidc-context";
import axios from "axios";

const AwaitApproval = () => {

  const { user } = useAuth();

  useEffect(() => {

    // TODO: PLEASE REFACTOR THIS! I WAS FORCED TO DO IT
    const apiBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    axios.get(`${apiBaseUrl}/users/user-sync?userId=${user.profile.sub}`, {
      headers: {
        Authorization: "Bearer " + user.access_token,
      }
    })
  }, []);

  return (
    <div className="pt-5 container-sm">
      <div className="row">
        <div className="col-sm-5">
          <div className="ps-5">
            <p className="ic" style={{ fontFamily: "Roboto" }}>
              <h5>Sejam bem-vindo</h5>
              <h4>À Gestão de Estrada</h4>
            </p>
            <p>
              A sua conta ainda não foi aprovada. Por favor, aguarde pela
              aprovação de sua conta.
            </p>
          </div>
        </div>
        <div className="col-sm-7">
          <img
            className="lg"
            src={himg}
            style={{ width: "80%", height: "100%" }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AwaitApproval;
