import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";
import { toast } from "react-toastify";
import debounce from "lodash.debounce"; // Importa debounce para otimizar chamadas

const ListCompany = () => {
    const url = process.env.REACT_APP_BACKEND_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const { user } = useAuth();
    const toastStyle = { fontSize: "13.3px" };

    const columnTitles = [
        { key: "name", value: "Nome" },
        { key: "nif", value: "NIF" },
        { key: "email", value: "Email" },
    ];

    // Busca todas as empresas
    const getCompanies = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${url}/company`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });

            const data = response.data.map((v) => ({
                id: v.id,
                name: v.name,
                nif: v.nif,
                email: !v.email || v.email === "NULL" ? "-" : v.email,
                created_at: v.created_at,
            }));

            // Ordena as empresas por data de criação
            data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            setCompanies(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao buscar empresas", { style: toastStyle });
        } finally {
            setLoading(false);
        }
    }, [url, user.access_token]);

    // Busca empresas filtradas no endpoint /company/search
    const fetchFilteredCompanies = async (searchTerm) => {
        if (!searchTerm) {
            getCompanies(); // Se a busca estiver vazia, recarrega todas as empresas
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(
                `${url}/company/search`,
                { search: searchTerm }, // Envia o termo de busca no corpo da requisição
                {
                    headers: {
                        Authorization: "Bearer " + user.access_token,
                    },
                }
            );

            // Formata os dados retornados
            const data = response.data.map((v) => ({
                id: v.id,
                name: v.name,
                nif: v.nif,
                email: !v.email || v.email === "NULL" ? "-" : v.email,
                created_at: v.created_at,
            }));

            setCompanies(data);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao buscar empresas filtradas", { style: toastStyle });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompanies();
    }, [getCompanies]);

    const deleteCompany = async (id) => {
        try {
            await axios.delete(`${url}/company/${id}`, {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            });
            toast.success("Eliminado com sucesso", { style: toastStyle });
            await getCompanies();
        } catch (error) {
            console.log(error);
            toast.error("Erro ao eliminar", { style: toastStyle });
        }
    };

    // Filtragem com debounce para evitar chamadas excessivas à API
    const filter = debounce((e) => {
        const searchTerm = e.target.value.trim();
        fetchFilteredCompanies(searchTerm);
    }, 500); // Aguarda 500ms antes de fazer a requisição

    return (
        <List
            title="Empresa"
            columnTitles={columnTitles}
            items={companies}
            filter={filter}
            originLink="/listCompany#"
            newLink="/addCompany"
            viewBaseLink="/viewCompany"
            editBaseLink="/editCompany"
            deleteItem={deleteCompany}
            loading={loading}
        />
    );
};

export default ListCompany;
